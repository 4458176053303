#staff-header-container{
    background: $iceBlue;
    height: 280px;
    >div{
        display: table;
        width: 100%;
        height: 100%;
        >div{
            display: table-cell;
            width: 100%;
            height: 100%;
            vertical-align: middle;
            h1{
                margin-bottom: 0;
            }
            h2{
                margin: 0;
                margin-top: 20px;
            }
        }
    }
}

#staff-introduction-container{
    padding: 80px 0 50px;
    text-align: center;
}

#staff-filter-container{
    padding: 25px 0;
    background: $teal;
    >div{
        >div{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            &.full-width{
                >div{
                    &:first-child{
                        width: 80%;
                        >div{
                            width: 100%;
                            position: relative;
                            .icon-search{
                                position: absolute;
                                color: $teal;
                                top: 12px;
                                right: 10px;
                                &:before{
                                    content: '\e90e';
                                }
                            }
                        }
                    }
                    &:last-child{
                        width: 18%;
                        button{
                            background-color: transparent;
                            border: none;
                            color: $white;
                            text-transform: uppercase;
                            letter-spacing: 2px;
                            font-weight: 600;
                            padding: 7px 10px;
                            opacity: 1;
                            &:hover{
                                opacity: 0.7;
                            }
                            .icon-close{
                                font-size: 14px;
                                margin-right: 5px;
                                &:before{
                                    content: '\e905';
                                }
                            }
                        }
                    }
                }
            }
            >div{
                width: 49%;
                display: flex;
                flex-direction: row;
                @include align-center;
                &:nth-of-type(2n){
                    margin-left: 2%;
                    >div{
                        position: relative;
                        width: 100%;
                        .icon-search{
                            position: absolute;
                            color: $teal;
                            top: 12px;
                            right: 10px;
                            &:before{
                                content: '\e90e';
                            }
                        }
                    }
                }
                label{
                    color: $white;
                    padding: 0 15px 0 0;
                    font-size: 20px;
                    font-weight: 600;
                    position: relative;
                    top: 2px;
                }
                .select-wrapper{
                    width: 100%;
                    position: relative;
                    height: 40px;
                    border-radius: 5px;
                    &:after{
                        position: absolute;
                        content: '\e90c';
                        top: 8px;
                        right: 10px;
                        font-family: $iconFont;
                        color: $teal;
                    }
                    select{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 40px;
                        border-radius: 5px;
                        border: none;
                        padding: 0 15px;
                    }
                }
            }
        }
    }
}

#staff-department-details{
    padding: 80px 0 0px;
    text-align: center;
}

#staff-list-wrapper{
    padding: 80px 0;
    >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        >div{
            margin-bottom: 10px;
        }
        .team-biography-toggle{
            color: $black;
            &.active, &:hover{
                .team-details{
                    background: rgba(58, 175, 169, 0.2);
                    @include transition(all, 300ms, ease-in-out);
                }
            }
        }
        .team-details{
            border-radius: 10px;
            background: $iceBlue;
            padding: 20px;
            height: 420px;
            @include transition(all, 300ms, ease-in-out);
            img{
                border-radius: 10px;
                margin-bottom: 20px;
            }
            h3,h4{
                margin-bottom: 5px;
                color: $black;
            }
            p{
                margin-bottom: 10px;
                color: $teal;
            }
        }
        .team-details-photo{
            position: relative;
            height: 230px;
            width: 230px;
        }
        .team-biography{
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s ease-out;
            text-align: left;
            padding: 0 100px;
            margin-top: 50px;
            background: $white;
            position: relative;
            &.active{
                max-height: 1800px;
                transition: all 0.5s ease-in;
            }
        }
    }
}

#staff-callout-content{
    background: $iceBlue;
    padding: 80px 0;
    >div{
        >div{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            @include align-center;
            >div{
                width: 50%;
                &:first-child{
                    padding-right: 80px;
                }
                &:last-child{
                    img{
                        border-radius: 10px;
                        height: 480px;
                        object-fit: cover;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1170px){
    #staff-header-container{
        padding: 0 20px;
    }
    #staff-filter-container{
        padding: 25px 20px;
    }
    #staff-list-wrapper{
        padding: 80px 20px;
    }
    #staff-callout-content{
        padding: 80px 20px;
    }
}

@media screen and (min-width: 1001px){
    #staff-list-wrapper{
        >div{
            >div{
                width: 23%;
                &:nth-of-type(4n), &:nth-of-type(4n-1), &:nth-of-type(4n-2){
                    margin-left: 2.667%;
                }
                &:nth-of-type(4n){
                    .team-biography{
                        margin-left: -330%;
                    }
                }
                &:nth-of-type(4n-1){
                    .team-biography{
                        margin-left: -222%;
                    }
                }
                &:nth-of-type(4n-2){
                    .team-biography{
                        margin-left: -110%;
                    }
                }
                .team-biography{
                    width: 434%;
                }
            }
        }
    }
}

@media screen and (min-width: 551px) and (max-width: 1000px){
	#staff-list-wrapper{
        >div{
            >div{
                width: 49%;
                &:nth-of-type(2n){
                    margin-left: 2%;
                    .team-biography{
                        margin-left: -103%;
                    }
                }
                .team-biography{
                    width: 203%;
                }
			}
		}
	}
}

@media screen and (max-width: 1000px){
    #staff-introduction-container{
        padding: 80px 20px 50px;
    }
    #staff-department-details{
        padding: 80px 20px 0;
    }
}

@media screen and (max-width: 950px){
    #staff-callout-content{
        >div{
            >div{
                >div{
                    width: 100%;
                    &:first-child{
                        padding: 0 0 50px;
                    }
                    &:last-child{
                        img{
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px){
    #staff-header-container{
        height: 220px;
    }
    #staff-introduction-container{
        padding: 60px 20px 30px;
    }
    #staff-filter-container{
        >div{
            >div{
                &.full-width{
                    >div{
                        &:first-child, &:last-child{
                            width: 100%;
                        }
                        &:last-child{
                            margin: 10px 0 0;
                            button{
                                padding: 10px 0;
                            }
                        }
                    }
                }
                >div{
                    &:first-child, &:last-child{
                        width: 100%;
                    }
                    &:first-child{
                        flex-wrap: wrap;
                    }
                    &:last-child{
                        margin: 20px 0 0;
                    }
                    label{
                        display: block;
                        width: 100%;
                        padding: 0 0 10px;
                        font-size: 18px;
                    }
                }
            }
        }
    }
    #staff-department-details{
        padding: 60px 20px 0;
    }
    #staff-list-wrapper{
        >div{
            .team-details{
                height: auto;
            }
            .team-biography{
                padding: 0 10px;
            }
        }
    }
    #staff-callout-content{
        padding: 60px 20px;
    }
}


@media screen and (max-width: 550px){
	#staff-list-wrapper{
		>div{
            >div{
                >div{
                    margin-bottom: 40px;
                }
            }
        }
	}
}