#search-header-container{
    background: $iceBlue;
    height: 320px;
    >div{
        display: table;
        width: 100%;
        height: 100%;
        >div{
            display: table-cell;
            width: 100%;
            height: 100%;
            vertical-align: middle;
            >div{
                >div{
                    position: relative;
                    margin-top: 30px;
                    label{
                        color: $black;
                        &.visuallyhidden{
                            border: 0;
                            clip-path: rect(0,0,0,0);
                            height: 1px;
                            margin: -1px;
                            overflow: hidden;
                            padding: 0;
                            position: absolute;
                            width: 1px;
                        }
                    }
                    input[type="text"]{
                        border: none;
                        border-bottom: 1px solid rgb(23,36,42, 0.16);
                        font-size: 24px;
                        background: transparent;
                        height: 60px;
                        border-radius: 0;
                        padding: 0 30px 0 0;
                        font-family: $secondaryFont;
                        @include transition(all, 300ms, ease-in-out);
                        &:focus{
                            border: none;
                            border-bottom: 1px solid rgba(43, 122, 120, 0.8);
                            @include transition(all, 300ms, ease-in-out);
                        }
                    }
                    .icon-search{
                        color: $teal;
                        position: absolute;
                        right: 10px;
                        bottom: 18px;
                        font-size: 26px;
                        &:before{
                            content: '\e90e';
                        }
                    }
                }
            }
        }
    }
}

#search-results-header{
    padding: 50px 0 30px;
    h2{
        margin-bottom: 0;
        strong{
            color: $teal;
        }
    }
    p{
        margin-bottom: 0;
        font-style: italic;
        font-size: 22px;
        margin-top: 5px;
    }
}

#search-results{
    padding: 0 0 120px;
    .search-result-item{
        padding: 30px 0;
        border-bottom: 1px solid rgb(23,36,42, 0.16);
        h3{
            margin-bottom: 0;
        }
        p{
            margin-bottom: 0;
            margin-top: 10px;
        }
    }
}

#no-search-spacer{
    padding: 50px 0;
}

@media screen and (max-width: 1010px){
    #search-header-container{
        padding: 0 20px;
    }
    #search-results-header{
        padding: 50px 20px 30px;
    }
    #search-results{
        padding: 0 20px 120px;
    }
}

@media screen and (max-width: 767px){
    #search-header-container{
        height: 260px;
        >div{
            >div{
                >div{
                    >div{
                        input[type="text"]{
                            font-size: 20px;
                            height: 50px;
                        }
                        .icon-search{
                            font-size: 22px;
                            bottom: 14px;
                        }
                    }
                }
            }
        }
    }
    #search-results-header{
        p{
            font-size: 18px;
        }
    }
    #search-results{
        padding: 0 20px 80px;
    }
}