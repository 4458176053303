#header-container{
    padding: 30px 0;
    &.minimal-header{
        >div{
            justify-content: center;
            width: 100%;
        }
    }
    >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include align-center;
        >div{
            &:first-child{
                width: 220px;
            }
            &:last-child{
                width: calc(100% - 220px);
                >div{
                    text-align: right;
                    &:first-child{
                        margin-bottom: 15px;
                        ul{
                            padding: 0;
                            margin: 0;
                            list-style: none;
                            display: inline-block;
                            li{
                                display: inline-block;
                                &:last-child{
                                    span{
                                        display: none;
                                    }
                                }
                                span{
                                    position: relative;
                                    top: -1px;
                                    display: inline-block;
                                    margin: 0 10px;
                                }
                                a{
                                    color: $teal;
                                    text-decoration: none;
                                    font-weight: 500;
                                    &:hover{
                                        color: $teal;
                                    }
                                }
                            }
                        }
                        #desktop-search-toggle{
                            display: inline-block;
                            text-decoration: none;
                            color: $teal;
                            margin-left: 15px;
                            vertical-align: middle;
                            font-size: 20px;
                            &:hover{
                                color: $teal;
                            }
                            .icon-search{
                                &:before{
                                    content: '\e90e';
                                }
                            }
                        }
                    }
                    &:last-child{
                        ul{
                            padding: 0;
                            margin: 0;
                            list-style: none;
                            li{
                                display: inline-block;
                                position: static;
                                &:not(:last-of-type){
                                    margin-right: 20px;
                                }
                                &.menu-item-has-children{
                                    padding-right: 30px;
                                    margin-right: 15px;
                                    &:hover{
                                        .mega-menu{
                                            opacity: 1;
                                            visibility: visible;
                                            z-index: 1000;
                                            @include transition(all, 300ms, ease-in-out);
                                        }
                                    }
                                    >a, >span{
                                        padding-bottom: 30px;
                                        &:after{
                                            position: absolute;
                                            content: '\e90d';
                                            color: $teal;
                                            top: 3px;
                                            right: -22px;
                                            font-family: $iconFont;
                                            font-size: 16px;
                                        }
                                    }
                                }
                                a, span{
                                    font-size: 20px;
                                    color: $black;
                                    font-weight: bold;
                                    text-decoration: none;
                                    position: relative;
                                    &:not([href]){
                                        &:focus{
                                            outline: 1px solid $black;
                                        }
                                    }
                                    &:focus{
                                        outline: 1px solid $black;
                                    }
                                    &:hover{
                                        color: $teal;
                                    }
                                }
                                .mega-menu{
                                    position: absolute;
                                    opacity: 0;
                                    z-index: -1;
                                    visibility: hidden;
                                    width: 110%;
                                    left: -5%;
                                    max-width: 1500px;
                                    top: 100%;
                                    right: 0;
                                    @include transition(all, 300ms, ease-in-out);
                                    background: $cream;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    padding: 50px 130px;
                                    >div{
                                        &:first-child{
                                            width: 40%;
                                            padding-right: 50px;
                                            text-align: left;
                                            h1{
                                                font-family: $secondaryFont;
                                                font-size: 70px;
                                                line-height: 1.2;
                                                font-weight: 500;
                                                margin-bottom: 10px;
                                            }
                                            h2{
                                                font-family: $secondaryFont;
                                                font-size: 50px;
                                                line-height: 1.4;
                                                font-weight: 500;
                                                margin-bottom: 10px;
                                            }
                                            h3{
                                                font-family: $secondaryFont;
                                                font-size: 32px;
                                                line-height: 1.5;
                                                font-weight: 500;
                                                margin-bottom: 10px;
                                            }
                                            h4{
                                                font-family: $secondaryFont;
                                                font-size: 24px;
                                                line-height: 1.5;
                                                font-weight: 500;
                                                margin-bottom: 10px;
                                            }
                                            h5{
                                                font-family: $secondaryFont;
                                                font-size: 24px;
                                                line-height: 1.5;
                                                font-weight: 400;
                                                margin-bottom: 10px;
                                            }
                                            h6{
                                                font-size: 22px;
                                                margin-bottom: 10px;
                                                line-height: 1.55;
                                            }
                                            p{
                                                line-height: 1.667;
                                            }
                                        }
                                        &:last-child{
                                            width: 60%;
                                            text-align: left;
                                            >div{
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: wrap;
                                                >div{
                                                    width: 48%;
                                                    &:nth-of-type(2n){
                                                        margin-left: 4%;
                                                    }
                                                }
                                            }
                                            ul{
                                                padding: 0;
                                                list-style: none;
                                                margin: 0;
                                                >li{
                                                    width: 100%;
                                                    margin-bottom: 20px;
                                                    >a{
                                                        color: $teal;
                                                        text-decoration: none;
                                                        &:hover{
                                                            color: $black !important;
                                                            .icon-arrow{
                                                                color: $black;
                                                                left: 5px;
                                                                @include transition(all, 300ms, ease-in-out);
                                                            }
                                                        }
                                                        .icon-arrow{
                                                            left: 0;
                                                            top: -1px;
                                                            color: $teal;
                                                            font-size: 16px;
                                                            position: relative;
                                                            margin-right: 15px;
                                                            display: inline-block;
                                                            vertical-align: middle;
                                                            @include transition(all, 300ms, ease-in-out);
                                                            &:before{
                                                                content: '\e901';
                                                            }
                                                        }
                                                    }
                                                    p{
                                                        line-height: 1.667;
                                                        margin-top: 5px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #mobile-search-toggle{
        display: none;
        text-decoration: none;
        color: $teal;
        margin-left: 15px;
        vertical-align: middle;
        font-size: 32px;
        position: absolute;
        right: 90px;
        top: 42px;
        &:hover{
            color: $teal;
        }
        .icon-search{
            &:before{
                content: '\e90e';
            }
        }
    }
    #mobile-menu-icon{
        display: none;
        position: absolute;
        top: 44px;
        right: 20px;
        width: 60px;
        height: 60px;
        text-decoration: none;
        line-height: 60px;
        color: $teal;
        text-align: center;
        &.active{
            span{
                @include transition(all, 300ms, ease-in-out);
                &.icon-menu{
                    opacity: 0;
                }
                &.icon-close{
                    opacity: 1;
                }
            }
        }
        span{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            &.icon-menu{
                font-size: 35px;
                opacity: 1;
                @include transition(all, 300ms, ease-in-out);
                &:before{
                    content: '\e906';
                }
            }
            &.icon-close{
                font-size: 35px;
                opacity: 0;
                @include transition(all, 300ms, ease-in-out);
                &:before{
                    content: '\e905';
                }
            }
        }
    }
}

#mobile-menu{
	display: block;
	position: fixed;
	left: 0;
	top: 120px;
	height: 100%;
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	transform: translateX(-100%);
	-webkit-transform: translateX(-100%);	
	transition-property: transform;
	transition-duration: 0.6s;
	z-index: 100000;
	background-color: $white;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	padding: 30px 20px 240px;
	&.is-visible{
		transform: translateX(0%);
		-webkit-transform: translateX(0%);		
	}
    #mobile-menu-main{
        ul{
            margin: 0;
            list-style: none;
            padding: 0;
            li{
                border-bottom: 1px solid rgba(43, 122, 120, 0.2);
                position: relative;
                &.menu-item-has-children{
                    >a{
                        position: relative;
                        &.active{
                            color: $teal;
                            &:after{
                                @include rotate(-180deg);
                                @include transition(all, 300ms, ease-in-out);
                            }
                        }
                        &:after{
                            position: absolute;
                            content: '\e90d';
                            color: $teal;
                            top: 15px;
                            right: -35px;
                            font-family: $iconFont;
                            font-size: 22px;
                            @include rotate(0deg);
                            @include transition(all, 300ms, ease-in-out);
                        }
                    }
                }
                a{
                    font-size: 20px;
                    color: $black;
                    font-weight: bold;
                    text-decoration: none;
                    padding: 15px 0;
                    display: inline-block;
                }
                .sub-menu{
                    max-height: 0;
                    overflow: hidden;
                    transition: all 0.5s ease-out;
                    background: $cream;
                    width: calc(100% + 40px);
                    left: -20px;
                    position: relative;
                    padding: 0 20px;
                    top: 1px;
                    &.active{
                        padding: 20px;
                        max-height: 500px;
                        transition: all 0.5s ease-in;
                    }
                    ul{
                        padding: 0;
                        margin: 0;
                        li{
                            border: none;
                            padding: 10px 0;
                            a{
                                padding: 0;
                                color: $teal;
                                text-decoration: none;
                                &:hover{
                                    color: $teal !important;
                                    .icon-arrow{
                                        left: 5px;
                                        @include transition(left, 300ms, ease-in-out);
                                    }
                                }
                                .icon-arrow{
                                    left: 0;
                                    font-size: 16px;
                                    position: relative;
                                    margin-right: 15px;
                                    display: inline-block;
                                    vertical-align: middle;
                                    @include transition(left, 300ms, ease-in-out);
                                    &:before{
                                        content: '\e901';
                                    }
                                }
                            }
                            p{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    #mobile-menu-secondary{
        padding: 50px 0 0;
        ul{
            margin: 0;
            list-style: none;
            padding: 0;
            li{
                margin-bottom: 10px;
                a{
                    font-size: 18px;
                    color: $teal;
                    text-decoration: none;
                    &:hover{
                        color: $teal;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1500px){
    #header-container{
        >div{
            >div{
                &:last-child{
                    >div{
                        &:last-child{
                            ul{
                                li{
                                    .mega-menu{
                                        width: 105%;
                                        left: -2.5%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1420px){
    #header-container{
        padding: 30px 20px;
        >div{
            >div{
                &:last-child{
                    >div{
                        &:last-child{
                            ul{
                                li{
                                    .mega-menu{
                                        padding: 50px 20px;
                                        width: 100%;
                                        left: 0%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1100px){
    #header-container{
        >div{
            >div{
                &:first-child{
                    width: 150px;
                }
                &:last-child{
                    width: calc(100% - 150px);
                }
            }
        }
    }
}

@media screen and (max-width: 1000px){
    #header-container{
        >div{
            >div{
                &:first-child{
                    width: 180px;
                }
                &:last-child{
                    display: none;
                }
            }
        }
        #mobile-search-toggle, #mobile-menu-icon{
            display: block;
        }
    }
}

@media screen and (max-width: 360px){
    #header-container{
        >div{
            >div{
                &:first-child{
                    width: 160px;
                }
            }
        }
        #mobile-search-toggle, #mobile-menu-icon{
            top: 48px;
        }
    }
    #mobile-menu{
        top: 110px;
    }
}