#content{
	#error-container{
		padding: 100px 20px;
		text-align: center;
	}
    h1{
        font-family: $secondaryFont;
        font-size: 48px;
        line-height: 1.2;
        font-weight: 500;
        margin-bottom: 20px;
		&.h2{
			margin: 0;
			font-size: 50px;
			line-height: 1.4;
		}
		strong{
			color: $teal;
			font-weight: 500;
		}
    }
    h2{
        font-family: $secondaryFont;
        font-size: 36px;
        line-height: 1.4;
        font-weight: 500;
        margin-bottom: 20px;
		&.widget-subtitle{
			font-size: 32px;
			line-height: 1.25;
			font-weight: 400;
		}
		&.subtitle{
			font-size: 22px;
			font-weight: 400;
			line-height: 1.5;
			font-family: $primaryFont;
		}
		&.subtitle-large{
			font-size: 32px;
			line-height: 1.3;
			font-weight: 600;
		}
		strong{
			color: $teal;
			font-weight: 500;
		}
    }
    h3{
        font-family: $secondaryFont;
        font-size: 24px;
        line-height: 1.5;
        font-weight: 500;
        margin-bottom: 20px;
    }
    h4{
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 1.55;
		font-family: $secondaryFont;
		font-weight: 500;;
        &+ol, &+ul{
            position: relative;
            top: -10px;
            margin-bottom: 0px;
        }
    }
	h5{
		font-size: 20px;
		font-weight: 500;
		font-family: $secondaryFont;
		margin-bottom: 20px;
		line-height: 1.56;
	}
    p{
        line-height: 1.667;
        margin-bottom: 30px;
		&.subtitle, &.subheading{
			font-size: 22px;
			font-weight: 400;
			line-height: 1.5;
		}
    }
	span{
		&.h2{
			margin: 0;
			display: block;
			font-family: $secondaryFont;
			font-size: 36px;
			line-height: 1.4;
			font-weight: 500;
			margin-bottom: 20px;
		}
		&.h3{
			margin: 0;
			display: block;
			font-family: $secondaryFont;
			font-size: 24px;
			line-height: 1.5;
			font-weight: 400;
			margin-bottom: 20px;
		}
		&.h4{
			margin: 0;
			display: block;
			font-family: $secondaryFont;
			font-size: 22px;
			margin-bottom: 20px;
			line-height: 1.55;
			&+ol, &+ul{
				position: relative;
				top: -10px;
				margin-bottom: 0px;
			}
		}
		&.h5{
			margin: 0;
			display: block;
			font-size: 20px;
			font-weight: 500;
			font-family: $secondaryFont;
			margin-bottom: 20px;
			line-height: 1.56;
		}
		&.p{
			margin: 0;
			display: block;
			line-height: 1.667;
			margin-bottom: 30px;
		}
	}
    blockquote{
        padding: 0;
        border: none;
        border-top: 2px solid $teal;
        border-bottom: 2px solid $teal;
        padding: 30px 0;
        text-align: center;
        h1, h2, h3, h4, h5, h6, p{
			&:last-of-type{
				margin-bottom: 0;
			}
            margin-bottom: 0;
			font-size: 24px;
			font-weight: 500;
			font-family: $secondaryFont;
			margin-bottom: 20px;
			line-height: 1.56;
		}
    }
    a{
        &:not(.button){
            color: $teal;
            text-decoration: none;
            &:hover{
                color: $teal;
            }
        }
        &.button{
            &.green{
                @include green-button;
            }
            &.green-arrow, &.arrow{
                @include green-arrow-button;
            }
            &.white{
                @include white-button;
            }
        }
    }
    ul{
		padding: 0;
		margin: 20px 0;
		list-style: none;
		li{
			padding-left: 25px;
			margin-bottom: 5px;
			position: relative;
			&:before{
				position: absolute;
				content: '\2022';
				left: 0;
				top: -1px;
				color: $teal;
				font-size: 20px;
			}
		}
	}
	ol{
		list-style-position: outside;
		margin: 20px 0;
		padding: 0;
		list-style: none;
		counter-reset: section;
		>li{
			margin-bottom: 10px;
			position: relative;
			padding-left: 20px;
			&:before{
				position: absolute;
				left: 0;
				top: 0px;
				counter-increment: section;
				content: counter(section) ". ";
				color: $teal;
			}
		}
	}
	label, legend{
		border: none;
		color: $teal;
		font-size: 16px;
		font-weight: normal;
		margin-bottom: 5px;
	}
	input[type="text"], input[type="email"], input[type="tel"]{
		width: 100%;
		height: 40px;
		border: 1px solid rgba(43, 122, 120, 0.3);
		border-radius: 5px;
		font-size: 18px;
		padding-left: 15px;
		@include transition(all, 300ms, ease-in-out);
		&:focus{
			border: 1px solid rgba(43, 122, 120, 0.8);
			@include transition(all, 300ms, ease-in-out);
		}
	}
	select{
		width: 100%;
		border: 1px solid rgba(43, 122, 120, 0.3);
		border-radius: 5px;
		font-size: 18px;
		height: 40px;
		padding-left: 15px;
		@include transition(all, 300ms, ease-in-out);
		&:focus{
			border: 1px solid rgba(43, 122, 120, 0.8);
			@include transition(all, 300ms, ease-in-out);
		}
	}
	.select-wrapper{
		display: inline-block;
		width: 100%;
		position: relative;
		&:before{
			position: absolute;
			content: '\e90c';
			top: 10px;
			right: 10px;
			color: $teal;
			font-size: 14px;
			font-family: $iconFont;
		}
	}
	textarea{
		width: 100%;
		height: 120px;
		border: 1px solid rgba(43, 122, 120, 0.3);
		border-radius: 5px;
		font-size: 18px;
		resize: none;
		padding: 10px 15px;
		@include transition(all, 300ms, ease-in-out);
		&:focus{
			border: 1px solid rgba(43, 122, 120, 0.8);
			@include transition(all, 300ms, ease-in-out);
		}
	}
	input[type="checkbox"]{
		display: none;
		&:checked{
			&+label{
				&:after{
					opacity: 1;
					@include transition(all, 300ms, ease-in-out);
				}
			}
		}
		&+label{
			color: $black;
			font-weight: 400;
			position: relative;
			padding-left: 30px;
			cursor: pointer;
			&:before{
				position: absolute;
				content: '';
				width: 20px;
				height: 20px;
				border-radius: 5px;
				background: $white;
				top: 2px;
				left: 0;
				border: 1px solid rgba(43, 122, 120, 0.3);
			}
			&:after{
				position: absolute;
				content: '\e902';
				font-family: $iconFont;
				font-size: 20px;
				top: -3px;
				left: 3px;
				color: $teal;
				opacity: 0;
				@include transition(all, 300ms, ease-in-out);
			}
		}
	}
	input[type="radio"]{
		display: none;
		&:checked{
			&+label{
				color: $teal;
				&:after{
					opacity: 1;
					@include transition(all, 300ms, ease-in-out);
				}
			}
		}
		&+label{
			color: $black;
			font-weight: 400;
			position: relative;
			padding-left: 30px;
			cursor: pointer;
			&:before{
				position: absolute;
				content: '';
				width: 20px;
				height: 20px;
				border-radius: 50%;
				background: $white;
				top: 2px;
				left: 0;
				border: 1px solid rgba(43, 122, 120, 0.3);
			}
			&:after{
				position: absolute;
				content: '';
				width: 12px;
				height: 12px;
				background: $teal;
				border-radius: 50%;
				top: 6px;
				left: 4px;
				opacity: 0;
				@include transition(all, 300ms, ease-in-out);
			}
		}
	}
	input[type="submit"], button[type="submit"]{
		@include green-button;
	}
	.validation_message{
		font-weight: normal;
		padding: 7px 0 0;
		margin: 0;
		font-size: 14px;
		color: $red;
		border: none;
		background: transparent;
	}
	.gform_confirmation_message{
		font-size: 18px;
		padding: 40px 0;
		text-align: left;
	}
	.gform_wrapper{
		width: 100%;
		margin: 0 auto;
		width: 100%;
		max-width: 770px;
		.gform_fields{
			padding: 0;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			.gfield{
				width: 100%;
				margin-bottom: 20px;
				ul{
					padding: 0;
				}
				&.gfield--width-half{
					width: 48%;
					padding: 0 15px 0 0;
				}
				&.gfield--width-full{
					width: 100%;
				}
				.ginput_container_select{
					position: relative;
					&:after{
						position: absolute;
						content: '\e90c';
						font-family: $iconFont;
						right: 10px;
						top: 8px;
						display: inline-block;
						color: $teal;
					}
				}
				.ginput_container_radio, .ginput_container_checkbox{
					position: relative;
					top: -10px;
					>div{
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						>div{
							width: 45%;
						}
					}
				}
				&.gfield_error{
					background: transparent;
					border: none;
					padding: 0;
					margin-top: 0 !important;
					margin-bottom: 10px !important;
					input[type="text"], input[type="email"], input[type="tel"]{
						border: 1px solid $red;
					}
					textarea{
						border: 1px solid $red;
					}
					label{
						color: $red;
						span{
							color: $red;
						}
					}
				}
				.ginput_container_checkbox{
					margin-top: 0;
					margin-bottom: -10px;
					.gfield_checkbox{
						>div{
							margin-bottom: 7px;
						}
					}
				}
			}
		}
		.gform_footer{
			padding: 40px 0 0;
			display: block;
			button{
				@include green-button;
			}
		}
		img{
			&.loading-icon {
				margin: 30px auto;
				display: block;
				width: 60px;
		   	}
		}
	}
    #large-cta-container{
		&.light{
			padding: 80px 0 0;
			#large-cta-container-image{
				height: 500px;
				&:before{
					position: absolute;
					content: '';
					width: 100%;
					height: 100%;
					background: linear-gradient(to bottom, $white, rgba(255, 255, 255, 0) 75%);
					top: 0;
					left: 0;
					display: inline-block;
					z-index: 10;
				}
				img{
					object-fit: cover;
					object-position: 50%;
				}
			}
			#large-cta-content{
				text-align: center;
				top: 50px;
				margin-top: -30px;
				position: relative;
				z-index: 100;
				h1, h2, h3, h4, h5, h6{
					margin-bottom: 40px;
					strong, em{
						color: $teal;
						font-style: normal;
					}
				}
				p{
					position: relative;
					top: -20px;
				}
				a:not(.button){
					color: $white;
				}
			}
		}
		&.dark{
			background-size: cover;
			background-position: 50%;
			background-size: cover;
			padding: 150px 0;
			text-align: center;
			&:before{
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				display: inline-block;
				background: rgba(23, 36, 42, 0.5);
				width: 100%;
				height: 100%;
			}
			h1, h2, h3, h4, h5, h6, p{
				margin-bottom: 40px;
				color: $white;
				strong, em{
					font-style: normal;
				}
			}
			p{
				position: relative;
				top: -20px;
			}
			a:not(.button){
				color: $white;
			}
		}
    }
    
    #call-to-action-container{
        padding: 65px 0;
        background-color: $teal;
        >div{
            >div{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                @include align-center;
                >div{
                    &:first-child{
                        width: 70%;
                        h1, h2, h3, h4, h5, h6, p, span{
                            color: $white;
                            margin-bottom: 0;
                        }
                        h2{
                            margin-bottom: 10px;
                        }
                    }
                    &:last-child{
                        width: 30%;
                        text-align: right;
                        .button.green{
                            &:hover{
                                border: 1px solid $teal;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1170px){
    #content{
        #call-to-action-container{
            padding: 65px 20px;
        }
    }
}

@media screen and (max-width: 900px){
    #content{
        #call-to-action-container{
            >div{
                >div{
                    >div{
                        &:first-child, &:last-child{
                            width: 100%;
                            text-align: center;
                        }
                        &:last-child{
                            padding: 30px 0 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 810px){
    #content{
        #large-cta-container{
			&.light{
				padding: 60px 0 0;
				#large-cta-content{
					padding: 0 20px;
				}
			}
			&.dark{
				padding: 150px 20px;
			}
        }
    }
}

@media screen and (max-width: 767px){
    #content{
		#error-container{
			padding: 50px 20px;
		}
        h1{
            font-size: 36px;
			&.h2{
				font-size: 38px;
			}
        }
        h2{
			&.subtitle{
				font-size: 18px;
			}
			&.subtitle-large{
				font-size: 22px;
			}
			&.widget-subtitle{
				font-size: 22px;
			}
        }
        h3{
            font-size: 22px;
        }
        h4{
            font-size: 18px;
        }
		h5{
			font-size: 16px;
		}
        p{
            font-size: 16px;
			&.subtitle, &.subheading{
				&+p{
					position: relative;
					top: -20px;
					margin-bottom: 10px;
				}
			}
        }
		a{
			&.button{
				&.green, &.white{
					display: block;
					width: 100%;
					max-width: 500px;
				}
			}
		}
		blockquote{
			h1, h2, h3, h4, h5, h6, p{
				font-size: 22px;
			}
		}
		span{
			&.h3{
				font-size: 22px;
			}
			&.h4{
				font-size: 20px;
			}
			&.h5{
				font-size: 16px;
			}
			&.p{
				font-size: 16px;
			}
		}
        input[type="text"], input[type="tel"], input[type="email"]{
	        font-size: 16px;
        }
        textarea, select{
	        font-size: 16px;
        }
        .gform_wrapper{
			.gform_fields{
				.gfield{
					&.gfield--width-half{
						width: 100%;
						padding: 0 15px 0 0;
					}
					.ginput_container_radio, .ginput_container_checkbox{
						>div{
							>div{
								width: 100%;
							}
						}
					}
				}
			}
		}
        #large-cta-container{
			&.light{
				h1, h2, h3, h4, h5, h6, p{
					margin-bottom: 25px;
				}
				#large-cta-container-image{
					height: 300px;
				}
			}
			&.dark{
				padding: 50px 20px;
			}
        }
        #call-to-action-container{
            padding: 50px 20px;
        }
    }
}