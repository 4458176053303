#hero-container{
	padding: 50px 0;
	min-height: 660px;
	#hero-content{
		>div{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			>div{
				width: 40%;
				padding: 50px 0;
				h1{
					font-size: 60px;
					strong{
						color: $teal;
					}
				}
				h2.subtitle{
					margin-bottom: 50px;
				}
				#hero-buttons{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					@include align-center;
					>div{
						&:last-child{
							margin-left: 10px;
						}
					}
				}
			}
		}
	}
	#hero-image-wrapper{
		position: absolute;
		width: 50%;
		top: 0;
		right: 0;
		height: 660px;
		img{
			object-fit: cover;
			object-position: left top;
		}
	}
}

#widgets-container{
	background: $iceBlue;
	padding: 90px 0;
	>div{
		>div{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			>div{
				padding: 20px;
				background: $iceBlue;
				border-radius: 10px;
				@include transition(all, 300ms, ease-in-out);
				&:hover{
					background: $white;
					@include transition(all, 300ms, ease-in-out);
				}
				h3, h4{
					margin-bottom: 10px;
					strong{
						color: $teal;
						font-weight: 500;
					}
				}
				.image-wrapper{
					margin-bottom: 20px;
					height: 220px;
					width: 100%;
					>span{
						height: 220px !important;
						width: 100% !important;
						img{
							object-fit: cover;
							border-radius: 10px;
							width: 100% !important;
						}
					}
				}
			}
		}
	}
}

#home-difference-container{
	padding: 120px 0;
	>div{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		@include align-center;
		>div{
			width: 50%;
			&:last-child{
				padding-left: 80px;
				h2{
					margin-bottom: 10px;
					strong{
						font-weight: 500;
					}
				}
				h2, p{
					strong{
						color: $teal;
					}
				}
			}
			.image-wrapper{
				position: relative;
				border-radius: 10px;
				img{
					border-radius: 10px;
					object-fit: cover;
				}
				>div{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					>div{
						display: table;
						width: 100%;
						height: 100%;
						>div{
							display: table-cell;
							width: 100%;
							height: 100%;
							text-align: center;
							vertical-align: middle;
							a{
								width: 100px;
								height: 100px;
								line-height: 120px;
								text-decoration: none;
								border-radius: 50%;
								background: $white;
								display: inline-block;
								.icon-play{
									font-size: 50px;
									position: relative;
									left: 5px;
									top: 6px;
									&:before{
										content: '\e910';
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
/*! purgecss start ignore */
#home-testimonials-container{
	background: rgba(251, 176, 59, 0.15);
	padding: 120px 0;
	.swiper-slide{
		padding: 30px 0 0;
		>div{
			padding: 60px 0 50px;
			border-bottom: 2px solid $teal;
			position: relative;
			&:before{
				position: absolute;
				content: '';
				width: calc(50% - 50px);
				top: 0;
				left: 0;
				height: 2px;
				background: $teal;
			}
			&:after{
				position: absolute;
				content: '';
				width: calc(50% - 50px);
				top: 0;
				right: 0;
				height: 2px;
				background: $teal;
			}
			>div{
				text-align: center;
				position: relative;
				&:before{
					position: absolute;
					content: '\e909';
					font-family: $iconFont;
					color: $teal;
					top: -95px;
					font-size: 50px;
					left: 0;
					right: 0;
					display: inline-block;
					margin: auto;
					text-align: center;
				}
				h5{
					margin-bottom: 30px;
				}
				p{
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
		}
	}
	.swiper-button-prev{
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: $teal;
		left: 0px;
		transform: translateY(-50%);
		top: 50%;
		&:hover{
			background: $teal;
		}
		&:after{
			content: '\e901';
			font-family: $iconFont;
			color: $white;
			display: inline-block;
			@include rotate(180deg);
			font-size: 	12px;
		}
	}
	.swiper-button-next{
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: $teal;
		right: 0px;
		transform: translateY(-50%);
		top: 50%;
		&:hover{
			background: $teal;
		}
		&:after{
			content: '\e901';
			font-family: $iconFont;
			color: $white;
			font-size: 14px;
		}
	}
	.swiper-pagination{
		position: relative;
		margin-top: 50px;
		.swiper-pagination-bullet{
			border-radius: 50%;
			border: 1px solid $teal;
			background: transparent;
			width: 12px;
			height: 12px;
			opacity: 1;
			@include transition(all, 300ms, ease-in-out);
			&:hover, &.swiper-pagination-bullet-active{
				background: $teal;
				@include transition(all, 300ms, ease-in-out);
			}
		}
	}
}
/*! purgecss end ignore */

#home-giving-container{
	padding: 120px 0;
	>div{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		>div{
			width: 48%;
			&:nth-child(2n-1){
				.button.arrow{
					margin-bottom: 10px;
				}
				h2{
					strong{
						color: $teal;
					}
				}
				ul{
					li{
						padding-left: 35px;
						margin-bottom: 20px;
						color: $teal;
						&:before{
							content: '\e901';
							font-family: $iconFont;
						}
					}
				}
			}
			&:nth-child(2n){
				margin-left: 4%;
				background: $teal;
				border-radius: 10px;
				position: relative;
				text-align: center;
				padding: 60px 40px;
				&:after{
		            position: absolute;
		            content: '';
		            right: 0px;
		            bottom: 0;
		            background-image: url('../images/footer-feather.svg');
		            background-size: cover;
		            background-position: top center;
		            width: 300px;
		            height: 250px;
		            opacity: 0.1;
		        }
				>div{
					position: relative;
					top: 50%;
					transform: translateY(-50%);
				}
		        h1, h2, h3,h4, h5, h6, p{
			        color: $white;
			        position: relative;
			        z-index: 100;
		        }
				a:not(.button){
					color: $white;
				}
		        .button{
			        position: relative;
			        z-index: 100;
			        margin-top: 20px;
                    &:hover{
                        border: 1px solid $teal;
                    }
		        }
			}
		}
	}	
}

#home-blog-container{
	background: $iceBlue;
	padding: 80px 0;
	>div{
		>div{
			&:first-child{
				text-align: center;
				margin-bottom: 40px;
				h2{
					strong{
						color: $teal;
					}
				}
			}
			&:nth-child(2){
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				>div{
					width: 48%;
					&:nth-child(1){
						>div{
							padding: 25px;
							border-radius: 10px;
							background: $iceBlue;
							@include transition(all, 300ms, ease-in-out);
							&:hover{
								background: $white;
				                @include transition(all, 300ms, ease-in-out);
							}
							.featured-image-link{
								display: inline-block;
								width: 100%;
								margin-bottom: 20px;
								>span{
									height: 220px !important;
									img{
										object-fit: cover;
										border-radius: 10px;
									}
								}
								img{
									border-radius: 10px;
									width: 100%;
								}
							}
							h3{
								margin-bottom: 10px;
							}
							div{
								a{
									&:last-child{
										span{
											display: none;
										}
									}
								}
							}
							span{
								&.sep{
									margin: 0;
								}
							}
							a.title-link{
								color: $black;
								&:hover{
									color: $teal;
								}
							}
						}
					}
					&:nth-child(2){
						margin-left: 4%;
						.blog-list-item{
				            display: flex;
				            flex-direction: row;
				            flex-wrap: wrap;
				            @include align-center;
				            margin-bottom: 15px;
				            padding: 25px;
				            border-radius: 10px;
				            background: $iceBlue;
				            @include transition(all, 300ms, ease-in-out);
				            &:hover{
				                background: $white;
				                @include transition(all, 300ms, ease-in-out);
				            }
				            >div{
				                &:first-child{
				                    width: 200px;
									a{
										>span{
											height: 120px !important;
											img{
												object-fit: cover;
												border-radius: 10px;
											}
										}
										img{
											border-radius: 10px;
											width: 100%;
										}
									}
				                }
				                &:last-child{
				                    width: calc(100% - 200px);
				                    padding-left: 20px;
				                    h4{
				                        margin-bottom: 10px;
				                    }
									a.title-link{
										color: $black;
										&:hover{
											color: $teal;
										}
									}
				                    .blog-list-details{
				                        margin-bottom: 20px;
				                        p, div{
				                            display: inline;
				                        }
				                        span{
				                            display: inline-block;
				                            margin: 0 5px;
				                            position: relative;
				                            top: -1px;
				                            color: $black;
											&.sep{
												margin: 0;
											}
				                        }
										div{
											a{
												&:last-child{
													span{
														display: none;
													}
												}
											}
										}
				                        p{
				                            margin-bottom: 0;
				                            color: $black;
				                        }
				                    }
				                }
				            }
				        }
					}
					.blog-list-details{
                        margin-bottom: 10px;
                        p, div{
                            display: inline;
                        }
                        span{
                            display: inline-block;
                            margin: 0 5px;
                            position: relative;
                            top: -1px;
                            color: $black;
                        }
                        p{
                            margin-bottom: 0;
                            color: $black;
                        }
                    }
                    .excerpt{
	                    padding: 20px 0;
                    }
                    .button{
	                    .icon-arrow{
		                    color: $teal;
	                    }
                    }
				}
			}
			&#home-blog-button-container{
				margin-top: 80px;
				text-align: center;
			}
		}
	}
}

@media screen and (max-width: 1210px){
	#hero-container{
		#hero-content{
			padding: 0 20px;
		}
		#hero-image-wrapper{
			width: 60%;
		}
	}
	#widgets-container{
		padding: 90px 20px;
	}
	#home-difference-container{
		padding: 120px 20px;
	}
	/*! purgecss start ignore */
	#home-testimonials-container{
		padding: 120px 20px;
	}
	/*! purgecss end ignore */
	#home-giving-container{
		padding: 120px 20px;
	}
	#home-blog-container{
		padding: 80px 20px;
	}
}

@media screen and (max-width: 1200px){
	#home-blog-container{
		>div{
			>div{
				&:nth-child(2){
					>div{
						&:first-child, &:last-child{
							width: 100%;
							margin-left: 0;
						}
						&:last-child{
							margin-top: 50px;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1001px){
	#widgets-container{
		>div{
			>div{
				>div{
					width: 32%;
					&:nth-of-type(3n-1){
						margin-left: 2%;
						margin-right: 2%;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1000px){
	#hero-container{
		padding: 0;
		#hero-content{
			>div{
				>div{
					width: 100%;
				}
			}
		}
		#hero-image-wrapper{
			position: relative;
			width: 100%;
			height: 500px;
			img{
				object-position: center;
				object-fit: contain;
			}
		}
	}
	#widgets-container{
		>div{
			>div{
				>div{
					width: 100%;
					max-width: 600px;
					margin: 0 auto;
					&:nth-of-type(3n-1){
						margin: 20px auto;
					}
					.image-wrapper{
						>span{
							height: 320px !important;
						}
					}
				}
			}
		}
	}
	#home-difference-container{
		>div{
			>div{
				&:first-child, &:last-child{
					width: 100%;
				}
				&:last-child{
					padding: 30px 0 0;
				}
			}
		}
	}
	#home-giving-container{
		>div{
			>div{
				&:first-child, &:last-child{
					width: 100%;
				}
				&:first-child{
					padding: 0 0 30px;
				}
				&:last-child{
					margin-left: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 767px){
	#hero-container{
		#hero-image-wrapper{
			height: 400px;
			img{
				object-position: center top;
				object-fit: cover;
			}
		}
		#hero-content{
			>div{
				>div{
					h1{
						font-size: 44px;
					}
				}
			}
		}
	}
	#widgets-container{
		>div{
			>div{
				>div{
					background: $white;
				}
			}
		}
	}
	#widgets-container{
		padding: 60px 20px;
		>div{
			>div{
				>div{
					.image-wrapper{
						>span{
							height: 200px !important;
						}
					}
				}
			}
		}
	}
	#home-difference-container{
		padding: 70px 20px;
		>div{
			>div{
				.image-wrapper{
					>div{
						>div{
							>div{
								a{
									width: 80px;
									height: 80px;
									line-height: 100px;
									.icon-play{
										font-size: 40px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	#home-giving-container{
		padding: 70px 20px;
		>div{
			>div{
				&:first-child{
					p{
						margin-bottom: 0;
						top: 0;
						position: relative;
						&.subheading{
							margin-bottom: 20px;
						}
					}
					.button.arrow{
						display: block;
						width: 100%;
						max-width: 500px;
						text-align: left;
						margin-bottom: 10px;
					}
					.button.green{
						margin-top: 20px;
					}
				}
			}
		}
	}
	#home-blog-container{
		padding: 60px 20px;
		>div{
			>div{
				&:first-child{
					margin-bottom: 20px;
				}
				&:nth-child(2){
					>div{
						&:nth-child(1){
							>div{
								background: $white;
							}
						}
						&:nth-child(2){
							.blog-list-item{
								background: $white;
								margin-bottom: 40px;
								>div{
									&:first-child, &:last-child{
										width: 100%;
										text-align: left;
										padding: 0;
									}
									&:first-child{
										a{
											>span{
												height: 180px !important;
											}
										}
									}
									&:last-child{
										margin-top: 20px;
									}
								}
							}
						}
					}
				}
				&#home-blog-button-container{
					margin-top: 30px;
				}
			}
		}
	}
}

@media screen and (max-width: 450px){
	#hero-container{
		#hero-image-wrapper{
			height: 300px;
		}
		#hero-content{
			>div{
				>div{
					#hero-buttons{
						>div{
							width: 100%;
							margin-bottom: 20px;
						}
					}
				}
			}
		}
	}
}